import React from 'react';
import api from 'api';
import withAuth from 'components/common/with-auth';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import styled from 'styled-components';

import { Container } from './styles';
import Main from 'components/common/main';
import theme from 'config/theme';
import Loading from 'components/common/page-loading';
import './student-dashboard.css';
import Favourite from './Favourite';
import { Title } from 'lib/Title';
import NoFavouritesImageUrl from '../../../assets/images/favourite-listings/no-favourites.png';

const { colors, fontWeights } = theme;

class FavouritesDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			favourites: null,
		};
	}

	loadFavourites = () => {
		api.favouritesV2
			.getFavourites()
			.then(res => res.data)
			.then(data => {
				this.setState({
					favourites: data,
				});
			})
			.catch(err => console.log(err));
	};

	componentDidMount() {
		this.loadFavourites();
	}

	render() {
		const { favourites } = this.state;
		var content = null;

		if (favourites == null) {
			return <Loading />;
		}

		if (favourites.length == 0) {
			content = (
				<>
					<Container>
						<Title>My favourites</Title>
						<NoFavouritesContainer>
							<img src={NoFavouritesImageUrl} />
							<Caption>You have no favourites yet.</Caption>
							<Text>
								Click <b>'Add to favourites'</b> on your favourite listings to have them show up
								here.
							</Text>
						</NoFavouritesContainer>
					</Container>
				</>
			);
		} else {
			content = (
				<>
					<Container>
						<Title>My favourites</Title>
						{favourites &&
							favourites.map((fav, i) => (
								<Favourite
									history={this.props.history}
									key={i}
									{...fav}
									reload={this.loadFavourites}
								/>
							))}
					</Container>
				</>
			);
		}

		return (
			<>
				<Header searchable />
				<Main style={{ backgroundColor: `${colors.lightGray}` }}>{content}</Main>
				<Footer />
			</>
		);
	}
}

export default withAuth(FavouritesDashboard);

const NoFavouritesContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

const Caption = styled.p`
	font-weight: ${fontWeights.bold};
	font-size: 20px;
`;

const Text = styled.p`
	text-align: center;
	font-size: 16px;
	color: ${colors.grey60};
	max-width: 19em;
`;
