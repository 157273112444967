import React, { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import styled from 'styled-components';
import api from 'api';
import OptionDots from '../../../assets/images/icons/more-vertical-outline.svg';
import UnfavouriteImageUrl from '../../../assets/images/favourite-listings/unheart.png';
import { notifySuccess } from 'lib/Notifications';
import Button from 'lib/Button';
import Cross from 'lib/Cross';
import theme from 'config/theme';
const { fontSizes, fontWeights, colors, fonts } = theme;

const Favourite = props => {
	const [showOptions, setShowOptions] = useState(false);
	const [showTitle, setShowTitle] = useState(false);
	const deleteFavourite = () => {
		api.favouritesV2.deleteFavourite(props.uuid).then(res => {
			notifySuccess('Removed successfully');
			props.reload();
		});
	};
	return (
		<>
			<BottomSheet
				open={showOptions}
				snapPoints={({ minHeight }) => minHeight}
				expandOnContentDrag={false}
			>
				<BottomSheetContainer>
					<BottomSheetHeader>
						<BottomSheetHeaderTextImage>
							<Cross onClick={() => setShowOptions(false)}></Cross>
						</BottomSheetHeaderTextImage>
						<BottomSheetHeaderText>More</BottomSheetHeaderText>
					</BottomSheetHeader>
					<BottomSheetButtonContainer>
						<BottomSheetButton
							onClick={() => {
								props.history.push(`/digs/view/${props.uuid}`);
							}}
						>
							<BottomSheetContainerButtonText>View</BottomSheetContainerButtonText>
						</BottomSheetButton>
					</BottomSheetButtonContainer>
					<BottomSheetDeleteButtonContainer>
						<BottomSheetButton onClick={() => deleteFavourite()}>
							<BottomSheetContainerButtonText>Unfavourite</BottomSheetContainerButtonText>
						</BottomSheetButton>
					</BottomSheetDeleteButtonContainer>
				</BottomSheetContainer>
			</BottomSheet>
			<Container>
				<Image src={props.photos[0].url} />
				<Content>
					<Title title={props.title}>{props.title}</Title>
					<ButtonsContainer>
						<ButtonContainer >
							<Button
								isOutline
								autoWidth
								onClick={() => {props.history.push(`/digs/view/${props.uuid}`);}}
							>
								View
							</Button>
						</ButtonContainer>
						<ButtonContainer>
							<Button onClick={() => deleteFavourite()} isOutline autoWidth>
								<UnfavouriteButtonContent>
									<ItemImage src={UnfavouriteImageUrl} />
									Unfavourite
								</UnfavouriteButtonContent>
							</Button>
						</ButtonContainer>
					</ButtonsContainer>
				</Content>
			</Container>
			<MobileContainer>
				<ListingInfoContainer onClick={() => {props.history.push(`/digs/view/${props.uuid}`);}}>
					<Image src={props.photos[0].url} />
					<Content>
						<Title title={props.title} onClick={() => setShowTitle(!showTitle)}>
							{showTitle ? props.title : props.title.slice(0, 15)}
							{!showTitle && props.title.length > 15 && '...'}
						</Title>
					</Content>
				</ListingInfoContainer>
				<OptionsButton onClick={() => setShowOptions(!showOptions)} />
			</MobileContainer>
		</>
	);
};

export default Favourite;

const Container = styled.div`
	border-radius: 16px;
	box-shadow: rgba(0, 0, 0, 0.14) 0 0 10px;
	background-color: white;
	overflow: hidden;
	display: flex;
	cursor: pointer;
	padding: 16px;
	align-items: center;
	margin-bottom: 16px;
	@media (max-width: 500px) {
		display: none;
	}
`;
const MobileContainer = styled(Container)`
	display: none;
	@media (max-width: 500px) {
		display: flex;
		justify-content: space-between;
	}
`;
const Content = styled.div`
	padding: 0 32px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	@media (max-width: 500px) {
		padding: 0;
	}
`;
const OptionsButton = styled.div`
  background-image: url("${OptionDots}");
  background-position: right;
  background-size: contain;
  width: 50px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
`;
const Image = styled.div`
	width: 130px;
	height: 80px;
	border-radius: 16px;
	background-image: ${({ src }) => `url("${src}")`};
	background-size: cover;
	background-position: center;
`;
const Title = styled.div`
	font-weight: 700;
	padding-left: 8px;
`;
const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
`;
const BottomSheetContainer = styled.div`
	justify-content: left;
	align-items: left;
`;
const BottomSheetContainerButtonText = styled.div`
	font-size: ${fontSizes.large};
	margin-left: 28px;
	color: ${colors.grey60};
	font-weight: ${fontWeights.bold};
`;
const BottomSheetHeader = styled.div`
	display: flex;
	padding: 16px;
	position: relative;
	width: 100%;
	justify-content: center;
	align-items: center;
`;
const BottomSheetHeaderText = styled.div`
	font-size: ${fontSizes.large};
	font-weight: ${fontWeights.bold};
	text-align: center;
`;
const ButtonContainer = styled.div`
	margin-left: 16px;
`;
const BottomSheetButtonContainer = styled.div`
	display: flex;
	border: 2px solid ${({ color }) => color || colors.grey01};
	padding: 16px;
`;
const BottomSheetDeleteButtonContainer = styled.div`
	display: flex;
	padding: 16px;
`;
const BottomSheetButton = styled.button`
	background-color: ${colors.white};
`;
const UnfavouriteButtonContent = styled.div`
	display: flex;
	align-items: center;
`;
const ItemImage = styled.img`
	margin-right: 12px;
`;

const BottomSheetHeaderTextImage = styled.div`
	margin-left: 28px;
	position: absolute;
	left: 0;
`;

const ListingInfoContainer = styled.div`
	display: flex;
`;